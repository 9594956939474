import { useSelector } from "react-redux"
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { useRef } from "react";


const LandingGrid = () => {
    const compareVideoRef = useRef(null);
    const searchVideoRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        compareVideoRef.current.play();
                        searchVideoRef.current.pause();

                    } else {
                        compareVideoRef.current.pause();
                        searchVideoRef.current.play();
                    }
                });
            },
            { threshold: 0.3 }
        );

        const videoElement = textRef.current;
        if (videoElement) {
            observer.observe(videoElement);
        }

        return () => {
            if (videoElement) {
                observer.unobserve(videoElement);
            }
        };
    }, []);


    return (
        <>


            <div className="bento-grid">
                <div class="bento-container">
                    <div class="search slab-bento vivify fadeIn duration-300 delay-400">
                        <div className="bento-text">
                            <img src="/assets/web/icons/search.svg" alt="search icon" />
                            <h2>
                                Search The Largest Food Delivery Database in the U.S.
                            </h2>
                            <p>
                                Over 300,000+ Restaurants in 300+ Cities across 5 Food Delivery Services
                            </p>
                        </div>

                        <video className="bento-video" ref={searchVideoRef} disableRemotePlayback muted playsInline>
                            <source src="/assets/web/video/search3.mp4" type="video/mp4" />
                        </video>

                    </div>
                    <div class="compare bento-tube vivify fadeIn duration-300 delay-600">
                        <video ref={compareVideoRef} className="portrait-bento-video" disableRemotePlayback muted playsInline>
                            <source src="/assets/web/video/compare2.mp4" type="video/mp4" />
                        </video>
                        <div className="bento-text" ref={textRef} style={{
                            marginTop: '5px',
                            marginBottom: '65px'
                        }}>

                            <h2>
                                Compare & Save on Food Delivery Fees
                            </h2>
                            <p>
                                Compare delivery fees across UberEats, Doordash, Grubhub, and more. Get the lowest delivery fee every time.
                            </p>
                        </div>
                    </div>
                    <div class="fastest bento-standard vivify fadeIn duration-300 delay-800">
                        <div className="bento-text">
                            <img src="/assets/web/icons/bolt.svg" alt="fast icon" />
                            <h2>
                                Fastest Delivery Times
                            </h2>
                            <p>
                                FoodScout automatically compares food delivery times across multiple services to get you the fastest delivery.
                            </p>
                        </div>
                    </div>
                    <div class="savings bento-standard vivify fadeIn duration-300 delay-1000">
                        <div className="bento-text">
                            <img src="/assets/web/icons/arrow.svg" alt="savings icon" />
                            <h2 style={{ maxWidth: 350 }}>
                                Users Save on Average $5 With Every Order
                            </h2>
                            <p>
                                On average, FoodScout users save $5 on every order by comparing fees and menu prices across multiple services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default LandingGrid