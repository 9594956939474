import { MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler"
import { Link } from "../../node_modules/react-router-dom/dist/index"

const NonLodable = () => {
    return (
        <>
            <Link to="/" > wow</Link>
            <div>
            {/* <MetaTagHandler id="metaTagDog" pathway={'getDog'} data={{breed:'husky'}} fetchHandler={'/api'} >
                {() => (
                    <>
                        <title {...serverHtmlAttributes({text: "metaTagDog.text"})}></title>
                    </>
                )}
            </MetaTagHandler> */}
            </div>
          
        <h2>This should load within html by default, but with loadable only if the user views this component.</h2>
        </>
    )
}

export default NonLodable