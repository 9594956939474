import { useState } from "react"

const PrivacyPolicy = () => {

    const [state, setState] = useState()

    return (
        <>
            <style>

                {
                    `
        body {
            font-family: Arial, sans-serif;
       
          
            font-size: 16px;
 
        }
        h1, h2 {
            color: #333;
            margin-bottom: 6px;
            margin-top: 26px;
        
        }
        p, li {
            line-height: 1.6;
            color: #666;
        
        }
        `
                }
            </style>
            <div className="lg-text-container">

                <h1>Privacy Policy</h1>
                <p>Welcome to FoodScout App, accessible via our website at foodscoutapp.com and our mobile application "FoodScout App". This privacy policy aims to inform you about the types of information we collect through our website and app and how we use this information.</p>

                <h2>Information Collection and Use</h2>

                <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information. However, as specified, we do not directly collect personal information like names, email addresses, or payment information from our users.</p>

                <h2>Analytics</h2>

                <p>We use third-party services such as Google Analytics and Microsoft Clarity for analytics purposes. These services collect data such as your IP address, browser type, browser version, our Service pages that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

                <h2>Cookies and Tracking Technologies</h2>

                <p>We do not use cookies or similar tracking technologies to monitor and collect information on our Service.</p>

                <h2>Use of Data</h2>

                <p>The data we collect through analytics tools are used exclusively for internal review to improve our Service. We do not share this data with any third parties.</p>

                <h2>Children's Privacy</h2>

                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

                <h2>Changes to This Privacy Policy</h2>

                <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@foodscoutapp.com.</p>
            </div>
        </>
    )
}

export default PrivacyPolicy