import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Render from './render';
import { createReduxStore } from './store/store';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component'


const store = createReduxStore(window.serverReduxStore)


if (process.env.NODE_COMPILE == 'design') {

    const root = createRoot(document.getElementById('root'));
    root.render(<Provider store={store}><Render /></Provider>);
    if (module.hot) {
        module.hot.accept('./index.js', () => {
            const NextRender = require('./index.js').default
            root.render(<Provider store={store}><NextRender /></Provider>, root);
        });
    }
}
else {
    if (process.env.NODE_COMPILE == 'development') {
        const root = createRoot(document.getElementById('root'));
        root.render(<Provider store={store}><Render /></Provider>);
        if (module.hot) {
            module.hot.accept(() => {
                // Re-hydrate or re-render your app logic here
                hydrateRoot(document.getElementById('root'), <Provider store={store}><Render /></Provider>);
            });
        }
    }
    else {
        loadableReady(() => {
            hydrateRoot(document.getElementById('root'), <Provider store={store}><Render /></Provider>)
        })
    }
}

