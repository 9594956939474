import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './publicPages/landingPage';
import NonLodable from './publicPages/nonLodable';
import loadable from '@loadable/component';
import Page404 from './publicPages/404';
import Header from './publicPages/components/header';
import PrivacyPolicy from './publicPages/privacyPolicy';
import SubscriptionTerms from './publicPages/subscription-terms';
import Cities from './publicPages/cities';
import './css/app.css'
import './css/vivify.min.css'


const LoadableLandingPage = loadable(() => import('./publicPages/lodable'))


const App = () => {



    return (
        <>
            <Header />
            <Routes>
                <Route path='/' >
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/lodable' element={<LoadableLandingPage />} />
                    <Route path='/nonLodable' element={<NonLodable />} />
                    <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
                    <Route path='/subscriptionTerms' element={<SubscriptionTerms />} />
                    <Route path="*" element={<Page404 />} />
                    <Route path='/cities' element={<Cities />} />
                </Route>


            </Routes>
        </>


    )
};

export default App;
