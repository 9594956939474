import { useState } from "react"
import { Link } from "react-router-dom"


const Header = () => {

    const [state, setState] = useState()

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/">
                    <img src="/assets/web/LogoColor.svg" alt="FoodScout Logo" />
                </Link>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/cities">Cities</Link>
                    </li>
                    <li>
                        <Link to="mailto:support@foodscoutapp.com">Contact Us</Link>
                    </li>
                    <li>
                        <Link
                            to="https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396"
                        >Download Now</Link>
                    </li>

                    {/*  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
 */}


                </ul>
                <button className="download-btn" onClick={() => {
                    window.location.href = "https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396";
                }}>
                    <img src={"/assets/web/download.svg"} alt="download on the appstore" />
                </button>
            </div>
        </nav>

    )
}

export default Header