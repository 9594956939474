import { useState } from "react"

const SubscriptionTerms = () => {

    const [state, setState] = useState()

    return (
        <>
            <style>

                {
                    `
        body {
            font-family: Arial, sans-serif;
       
          
            font-size: 16px;
 
        }
        h1, h2 {
            color: #333;
            margin-bottom: 6px;
            margin-top: 26px;
        
        }
        p, li {
            line-height: 1.6;
            color: #666;
        
        }
        `
                }
            </style>
            <div className="lg-text-container">

                <h1>Subscription Terms</h1>
                <ol>
                    <li>By subscribing to FoodScout App, you agree to our Privacy Policy and Terms of Service.</li>

                    <li>Subscriptions are billed on a monthly or yearly basis, depending on the subscription plan you choose. Payment will be charged to your Apple ID at the time of purchase. The subscription automatically renews unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscriptions by going to your account settings on the App Store after purchase.</li>

                    <li>Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.</li>

                    <li>
                        While I endeavour to provide continuous access to the Service, I cannot guarantee that the Service will always be available, uninterrupted, secure, or error-free. I will not be liable to you if for any reason the Service is unavailable at any time or for any period.
                    </li>

                    <li>
                        I may change, suspend, or discontinue the Service at any time, including the availability of any feature, database, or content. I may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability.
                    </li>

                    <li>
                        I may, at my sole discretion, terminate your subscription and access to the Service at any time without notice. If I do so, you will be entitled to a refund of any amounts paid by you in respect of the Service for the period of time after termination.
                    </li>

                </ol>


                <h2>Contact Us</h2>

                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@foodscoutapp.com.</p>
            </div>
        </>
    )
}

export default SubscriptionTerms