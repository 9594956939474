import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useNavigate } from "../../node_modules/react-router-dom/dist/index";
import { useEffect } from "react";
import { LoopServerCall, MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler";
import NonLodable from "./nonLodable";
import { useRef } from "react";
import Cities from "./cities";
import LandingGrid from "./components/landingGrid";

const LandingPage = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        // Check if videoRef.current is not null

        if (videoRef.current) {
            videoRef.current.playbackRate = 1.5; // Set the playback speed to 1.5
        }
    }, []); // Empty dependency array means this effect runs once after the initial render


    return (
        <>


            {/* <MetaTagHandler >
                {() => (
                    <>
                        <title>Poodle</title>
                    </>
                )}
            </MetaTagHandler>  */}
            {/* <NonLodable /> */}

            {/* <h2><ServerCallHandler id={"getDogHusky"} pathway={'getDog'} data={{breed:'husky'}} fetchHandler={'/api'} overwrite={'getDogHusky.src'} /></h2> */}


            <div className="hero">
                <div className="hero-wrapper">
                    <div className="hero-text ">
                        <img src="/assets/web/appicon.png" alt="FoodScout Logo" />
                        <h1>Your Food Delivery Search Engine</h1>
                        <p>
                            One App For UberEats, Doordash, Grubhub & More
                        </p>
                        <button className="download-btn" onClick={() => {
                            window.location.href = "https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396";
                        }}>
                            <img src={"/assets/web/download.svg"} alt="download on the appstore" />
                        </button>
                    </div>
                    {/* <div className="hero-media vivify fadeIn duration-300 delay-1000" >

                        <video disableRemotePlayback autoPlay loop muted playsInline ref={videoRef}>
                            <source src="/assets/web/demovideo5.mp4" type="video/mp4" />
                        </video>
                        <div className="hero-img">
                            <img src="/assets/web/FoodScoutScreenshot.png" alt="hero" />
                        </div>
                    </div> */}
                </div>
            </div>

            <LandingGrid />

            <Cities />

        </>
    )
}

export default LandingPage